import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Tab, Row, ListGroup, Col } from 'react-bootstrap';
require('dotenv').config();

export const ProfileScreen = ({ history }) => {
  const [me, setMe] = useState(0);

  const userInfo = useSelector((state) => state.user);
  const { user } = userInfo;

  const redirect = '/login';

  const getMe = async () => {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + '/users/me/',
      {
        headers: {
          Authorization: 'Bearer ' + user.jwt,
        },
      }
    );

    setMe(data);
  };

  useEffect(() => {
    if (user === null || user === {}) {
      history.push(redirect);
    }

    getMe();
  }, [redirect, user]);

  return (
    <div>
      <header className='masthead content'>
        <div className='layer'></div>

        <div className='container h-100'>
          <div className='row h-100 align-items-center'>
            <div className='col-12 text-center'>
              <h1 className='font-weight-light' style={{ color: '#fff' }}>
                Profile
              </h1>
            </div>
          </div>
        </div>
      </header>

      <section className='py-5'>
        <div className='container'>
          <Tab.Container id='list-group-tabs-example' defaultActiveKey='#link1'>
            <Row>
              <Col sm={4}>
                <ListGroup className='mb-3'>
                  <ListGroup.Item action href='#link1'>
                    Account Information
                  </ListGroup.Item>
                  <ListGroup.Item action href='#link2'>
                    Current Usage Limit
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col sm={8}>
                <Tab.Content>
                  <Tab.Pane eventKey='#link1'>
                    <p>Username: {me.username}</p>
                    <p>Email: {me.email}</p>
                    <p>BNI Chapter: {me.chapter}</p>
                  </Tab.Pane>
                  <Tab.Pane eventKey='#link2'>
                    <p>
                      Current Usage Limit: {me.usageLimit ? me.usageLimit : 0}
                    </p>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </section>
    </div>
  );
};
