import { createStore, combineReducers, applyMiddleware } from 'redux';
import { suggestionReducer } from './reducers/suggestionReducers';
import { userReducer } from './reducers/userReducers';
import thunk from 'redux-thunk';

const reducer = combineReducers({
  suggestion: suggestionReducer,
  user: userReducer,
});

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null;

const initialState = {
  suggestion: { suggestion: {} },
  user: { user: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  applyMiddleware(...middleware)
);

export default store;
