import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
// import html2pdf from 'html2pdf.js';
// import jsPDF from 'jspdf';
// import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

require("dotenv").config();

const SuggestionResultScreen = ({ history }) => {
  const suggestionState = useSelector((state) => state.suggestion);
  const { suggestion } = suggestionState;
  const [me, setMe] = useState(0);

  const [img, setImg] = useState("");
  const [usageLimit, setUsageLimit] = useState(0);
  const [scrolledToTop, setScrolledToTop] = useState(false);

  const userInfo = useSelector((state) => state.user);
  const { user } = userInfo;

  const redirect = "/login";

  // const exportHTMLToPDF = async (pages, fileName, outputType = 'blob') => {
  //   const opt = {
  //     margin: [0, 0],
  //     filename: fileName,
  //     image: { type: 'jpeg', quality: 0.98 },
  //     html2canvas: { dpi: 192, letterRendering: true },
  //   };

  //   await html2pdf().from(pages).set(opt).save();
  // };

  const numberWithCommas = (x) => {
    if (!x) {
      return "";
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const getUsageLimit = async () => {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/bniLogics/getUserUsageLimit/",
      {
        headers: {
          Authorization: "Bearer " + user.jwt,
        },
      }
    );

    setUsageLimit(res.data.usageLimit ? res.data.usageLimit : 0);
  };

  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  useEffect(() => {
    if (
      suggestion == null ||
      suggestion == undefined ||
      suggestion.results == null ||
      suggestion.results == undefined ||
      suggestion.results.length <= 0
    ) {
      history.push("/suggestion");
      return;
    }

    if (user === null) {
      history.push(redirect);
      return;
    } else {
      getUsageLimit();
      getMe();
    }

    if (scrolledToTop == false) {
      setScrolledToTop(true);
      window.scrollTo(0, 0);
    }
  }, [suggestion, redirect, user]);

  const getMe = async () => {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + "/users/me/",
      {
        headers: {
          Authorization: "Bearer " + user.jwt,
        },
      }
    );

    setMe(data);
  };

  const back = () => {
    history.goBack();
  };

  // const downloadFullReport = async () => {
  //   const currentDate = new Date();

  //   const fileName = `QuanTra-full-report-${currentDate.getFullYear()}${
  //     currentDate.getMonth() + 1
  //   }${
  //     currentDate.getDate() < 10
  //       ? '0' + currentDate.getDate()
  //       : currentDate.getDate()
  //   }${
  //     currentDate.getHours() < 10
  //       ? '0' + currentDate.getHours()
  //       : currentDate.getHours()
  //   }${
  //     currentDate.getMinutes() < 10
  //       ? '0' + currentDate.getMinutes()
  //       : currentDate.getMinutes()
  //   }${
  //     currentDate.getSeconds() < 10
  //       ? '0' + currentDate.getSeconds()
  //       : currentDate.getSeconds()
  //   }.pdf`;

  //   var data = document.getElementById('full');

  //   // var opt = {
  //   //   margin: 1,
  //   //   filename: fileName,
  //   //   // image: { type: 'jpeg', quality: 0.95 },
  //   //   html2canvas: {
  //   //     allowTaint: true,
  //   //     // foreignObjectRendering: true,
  //   //     useCORS: true,
  //   //   },
  //   // };

  //   // const pages = suggestion.results.map((sr) =>
  //   //   document.getElementById(sr.cell)
  //   // );

  //   await exportHTMLToPDF(data, fileName);
  // };

  // const downloadPersonalReport = async (memberSuggestion) => {
  //   const currentDate = new Date();

  //   const fileName = `QuanTra-personal-report-${memberSuggestion.name.replace(
  //     ' ',
  //     '-'
  //   )}-${currentDate.getFullYear()}${currentDate.getMonth() + 1}${
  //     currentDate.getDate() < 10
  //       ? '0' + currentDate.getDate()
  //       : currentDate.getDate()
  //   }${
  //     currentDate.getHours() < 10
  //       ? '0' + currentDate.getHours()
  //       : currentDate.getHours()
  //   }${
  //     currentDate.getMinutes() < 10
  //       ? '0' + currentDate.getMinutes()
  //       : currentDate.getMinutes()
  //   }${
  //     currentDate.getSeconds() < 10
  //       ? '0' + currentDate.getSeconds()
  //       : currentDate.getSeconds()
  //   }.pdf`;

  //   var data = document.getElementById(memberSuggestion.cell);
  //   console.log(data);

  //   var opt = {
  //     margin: 1,
  //     filename: fileName,
  //     image: { type: 'jpeg', quality: 0.95 },
  //     html2canvas: {
  //       allowTaint: true,
  //       // foreignObjectRendering: true,
  //       useCORS: true,
  //     },
  //     jsPDF: {
  //       orientation: 'p',
  //       unit: 'mm',
  //       format: 'a4',
  //       putOnlyUsedFonts: true,
  //       floatPrecision: 16,
  //     },
  //   };

  //   await exportHTMLToPDF([data], fileName);
  // };

  const sendWtsMsg = async (memberSuggestion, isWeb) => {
    // console.log(memberSuggestion);
    var to = prompt("Please enter member's phone # (e.g. 85292025576)", "");
    let isNum = /^\d+$/.test(to);

    // if (to == null || to == undefined || to.trim().length <= 0 || !isNum) {
    //   return;
    // }

    let msg = "";

    if (memberSuggestion.totalScore >= 70) {
      msg = `💌💌 ${me.chapter} Love Letter 💌💌\n你好，親愛嘅 *${memberSuggestion.name}* \n\n`;
      msg +=
        "👏🏻👏🏻 恭喜晒，你已經係 🟢 綠燈會員啦！！多謝你為我哋分會出一分力 💪🏻💪🏻 辛苦晒你 🫂❤️\n\n";
      msg += `根據過往5個月的數據紀錄，如果今個月在什麼都不做的情況下，你會是 ${memberSuggestion.totalScore} 分的 Green 燈會員 ☺️\n\n`;

      msg += `過往五個月的遲到數目: ${memberSuggestion.currentLate}次 \n過往五個月的缺席數目: ${memberSuggestion.currentAbs}次\n\n`;
    } else {
      msg = `💌💌 ${me.chapter} Love Letter 💌💌\n你好，親愛嘅 *${
        memberSuggestion.name
      }*\n根據過往5個月的數據紀錄，如果今個月在什麼都不做的情況下，你會是 ${memberSuggestion.totalScore} 分的 *${
        memberSuggestion.totalScore >= 70
          ? "Green"
          : memberSuggestion.totalScore >= 50
          ? "Yellow"
          : memberSuggestion.totalScore >= 30
          ? "Red"
          : "Black"
      }* 燈會員 ☺️\n\n`;

      msg += `過往五個月的遲到數目: ${memberSuggestion.currentLate}次 \n過往五個月的缺席數目: ${memberSuggestion.currentAbs}次\n\n`;
    }

    if (memberSuggestion.trainingSuggestionSet.length > 0) {
      msg += `=== 👩🏻‍🏫 Training 👨🏻‍🏫 === \n`;
      memberSuggestion.trainingSuggestionSet.forEach((ts) => {
        msg += `+${ts.move} Trainings = +${ts.score}分 \n`;
      });

      msg += "\n";
    }

    if (memberSuggestion.danceSuggestionSet.length > 0) {
      msg += `=== 👥 121 👥 ===  \n`;
      memberSuggestion.danceSuggestionSet.forEach((ds) => {
        msg += `+${ds.move} 121 Dances = +${ds.score}分 \n`;
      });

      msg += "\n";
    }

    if (memberSuggestion.referralSuggestionSet.length > 0) {
      msg += `=== 🤝 Referral 🤝 ===  \n`;
      memberSuggestion.referralSuggestionSet.forEach((rs) => {
        msg += `+${rs.move} Referrals = +${rs.score}分 \n`;
      });

      msg += "\n";
    }

    if (memberSuggestion.visitorSuggestionSet.length > 0) {
      msg += `=== 👨🏻‍💼 Visitor 🧑🏻‍💼 ===  \n`;
      memberSuggestion.visitorSuggestionSet.forEach((vs) => {
        msg += `+${vs.move} Visitors = +${vs.score}分 \n`;
      });

      msg += "\n";
    }

    if (memberSuggestion.bizSuggestionSet.length > 0) {
      msg += `=== 💰 Biz Give 💰 ===  \n`;
      memberSuggestion.bizSuggestionSet.forEach((bgs) => {
        msg += `+${formatter.format(bgs.move)} Biz Given = +${bgs.score}分 \n`;
      });

      msg += "\n";
    }

    if (memberSuggestion.totalScore >= 70) {
      msg += `如果只需完成以上部份事項，已經會成功繼續綠燈會員！ 🎉🎉🎉 \n努力向100分進發！我地 ${me.chapter} 會全力支持你嫁 💪💪 加油 💪💪`;
      // msg += `👏🏻👏🏻 恭喜晒，你已經係 🟢 綠燈會員啦！！多謝你為我哋分會出一分力 💪🏻💪🏻 辛苦晒你 🫂❤️\n\n只需完成以上部份事項，就會繼續維持成為綠燈會員 🎉🎉🎉。有你的參與好重要 ❤️ 我地 ${me.chapter} LT 會全力支持你嫁 💪💪 加油 💪💪`;
    } else {
      msg += `只需完成以上部份事項，已經會成功成為 🟢 70分或以上的綠燈會員！ 🎉🎉🎉 \n我地 ${me.chapter} LT 會全力支持你嫁 💪💪 加油 💪💪`;
    }

    // const url = process.env.REACT_APP_API_URL + '/whatsapp/messages';
    var url = "";
    if (isWeb) {
      url =
        "https://web.whatsapp.com/send?phone=" +
        to +
        "&text=" +
        encodeURIComponent(msg);
    } else {
      url =
        "https://api.whatsapp.com/send?phone=" +
        to +
        "&text=" +
        encodeURIComponent(msg);
    }

    window.open(url, "_blank");
    // window.open('whatsapp://send?text=Hello%20World!%0AAnother%20Word');

    // await axios.post(
    //   url,
    //   { to, msg },
    //   {
    //     headers: {
    //       Authorization: 'Bearer ' + user.jwt,
    //     },
    //   }
    // );

    // alert('Sent!');
  };

  return (
    <div className="container h-100 mt-4">
      <button onClick={back} className="btn btn-primary">
        Back
      </button>

      {/* <button onClick={() => window.print()} className='btn btn-warning'>
        Print Full Report
      </button> */}

      <div id="full">
        {suggestion.results
          ? suggestion.results.map((memberSuggestion, index) => (
              <div
                key={memberSuggestion.cell}
                className={`mb-5 mt-5`}
                id={memberSuggestion.cell}
              >
                <h2>
                  {memberSuggestion.name}'s Suggestion <br></br>
                  {/* <button
                    // onClick={() => downloadPersonalReport(memberSuggestion)}
                    className='btn btn-info mt-2'
                  >
                    Download Personal Report
                  </button> */}
                  <button
                    className="btn btn-success mt-2"
                    onClick={() => sendWtsMsg(memberSuggestion, true)}
                  >
                    Send WhatsApp WEB Message
                  </button>{" "}
                  <button
                    className="btn btn-success mt-2"
                    onClick={() => sendWtsMsg(memberSuggestion, false)}
                  >
                    Send WhatsApp APP Message
                  </button>
                </h2>

                <div className="row">
                  <div className="col-lg-2 col-sm-6">
                    <div className="circle-tile">
                      <div
                        className={`circle-tile-heading ${
                          memberSuggestion.totalScore >= 70
                            ? "green"
                            : memberSuggestion.totalScore >= 50
                            ? "yellow"
                            : memberSuggestion.totalScore >= 30
                            ? "red"
                            : "gray"
                        }`}
                      >
                        <i className="fa fa-star fa-fw fa-3x"></i>
                      </div>

                      <div
                        className={`circle-tile-content ${
                          memberSuggestion.totalScore >= 70
                            ? "green"
                            : memberSuggestion.totalScore >= 50
                            ? "yellow"
                            : memberSuggestion.totalScore >= 30
                            ? "red"
                            : "gray"
                        }`}
                      >
                        <div
                          className={`circle-tile-description ${
                            memberSuggestion.totalScore >= 70
                              ? "text-faded"
                              : memberSuggestion.totalScore >= 50
                              ? ""
                              : memberSuggestion.totalScore >= 30
                              ? "text-faded"
                              : ""
                          }`}
                        >
                          Next Month's Score
                        </div>
                        <div
                          className={`circle-tile-number ${
                            memberSuggestion.totalScore >= 70
                              ? "text-faded"
                              : memberSuggestion.totalScore >= 50
                              ? ""
                              : memberSuggestion.totalScore >= 30
                              ? "text-faded"
                              : ""
                          }`}
                        >
                          {memberSuggestion.totalScore}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr></hr>

                <div className="row">
                  {memberSuggestion.trainingSuggestionSet.map((tss) => (
                    <div
                      className="col-lg-2 col-sm-6"
                      key={tss.move.toString() + tss.score.toString()}
                    >
                      <div className="circle-tile">
                        <div className="circle-tile-heading blue">
                          <i className="fa fa-graduation-cap fa-fw fa-3x"></i>
                        </div>

                        <div className="circle-tile-content blue">
                          <div className="circle-tile-description text-faded">
                            +{tss.move} Trainings
                          </div>
                          <div className="circle-tile-number text-faded text-success">
                            +{tss.score}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="row">
                  {memberSuggestion.danceSuggestionSet.map((dss) => (
                    <div
                      className="col-lg-2 col-sm-6"
                      key={dss.move.toString() + dss.score.toString()}
                    >
                      <div className="circle-tile">
                        <div className="circle-tile-heading orange">
                          <i className="fa fa-address-book fa-fw fa-3x"></i>
                        </div>

                        <div className="circle-tile-content orange">
                          <div className="circle-tile-description text-faded">
                            +{dss.move} 121 Dances
                          </div>
                          <div
                            className="circle-tile-number"
                            style={{ color: "#00EE00" }}
                          >
                            +{dss.score}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="row">
                  {memberSuggestion.referralSuggestionSet.map((rss) => (
                    <div
                      className="col-lg-2 col-sm-6"
                      key={rss.move.toString() + rss.score.toString()}
                    >
                      <div className="circle-tile">
                        <div className="circle-tile-heading yellow">
                          <i className="fa fa-handshake fa-fw fa-3x"></i>
                        </div>

                        <div className="circle-tile-content yellow">
                          <div className="circle-tile-description">
                            +{rss.move} Referrals
                          </div>
                          <div className="circle-tile-number text-faded text-success">
                            +{rss.score}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="row">
                  {memberSuggestion.visitorSuggestionSet.map((vss) => (
                    <div
                      className="col-lg-2 col-sm-6"
                      key={vss.move.toString() + vss.score.toString()}
                    >
                      <div className="circle-tile">
                        <div className="circle-tile-heading purple">
                          <i className="fa fa-users fa-fw fa-3x"></i>
                        </div>

                        <div className="circle-tile-content purple">
                          <div className="circle-tile-description text-faded">
                            +{vss.move} Visitors
                          </div>
                          <div className="circle-tile-number text-faded text-success">
                            +{vss.score}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="row">
                  {memberSuggestion.bizSuggestionSet.map((bgss, index) => (
                    <div
                      className="col-lg-2 col-sm-6"
                      key={bgss.move.toString() + bgss.score.toString()}
                    >
                      <div
                        className="circle-tile"
                        style={{ display: "inline-block" }}
                      >
                        <div className="circle-tile-heading dark-blue">
                          <i className="fa fa-credit-card fa-fw fa-3x"></i>
                        </div>

                        <div className="circle-tile-content dark-blue">
                          <div className="circle-tile-description text-faded">
                            +{formatter.format(bgss.move)} Biz Given
                          </div>
                          <div className="circle-tile-number text-faded text-success">
                            +{bgss.score}
                          </div>
                        </div>
                      </div>
                      <div style={{ display: "inline-block" }}>
                        {index == memberSuggestion.bizSuggestionSet.length - 1}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))
          : ""}
      </div>

      <p>Current Usage Limit: {usageLimit}</p>
    </div>
  );
};

export default SuggestionResultScreen;
