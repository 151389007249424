import { SUGGESTION_SET_DATA } from '../constants/suggestionConstants';

export const suggestionReducer = (state = { suggestion: {} }, action) => {
  switch (action.type) {
    case SUGGESTION_SET_DATA:
      return {
        ...state,
        suggestion: action.payload,
      };
    default:
      return state;
  }
};
