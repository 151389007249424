import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import XLSX from 'xlsx';
import axios from 'axios';
import { setData } from '../actions/suggestionActions';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../actions/userActions';
import moment from 'moment';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import obLogo from '../images/OriginBit_black.png';
import bniQuantumLogo from '../images/bni_q_logo.png';
import bniRisingLogo from '../images/rising_logo.png';
require('dotenv').config();

const SuggestionScreen = ({ history }) => {
  const [trafficLightExcelReportInput, setTrafficLightExcelReportInput] =
    useState('Upload 5-month chapter PALMS report');
  const [fileUploaded, setFileUploaded] = useState(false);
  const [members, setMembers] = useState([]);
  const [usageLimit, setUsageLimit] = useState(0);

  const [form, setForm] = useState({
    currentAbs: 0,
    currentBizGiven: 0,
    currentDance: 0,
    currentLate: 0,
    currentReferral: 0,
    currentTraining: 0,
    currentVisitor: 0,
    targetType: 'GREEN_LIGHT_TARGET',
  });

  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user);
  const { user } = userInfo;

  const getUsageLimit = async () => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_API_URL + '/bniLogics/getUserUsageLimit/',
        {
          headers: {
            Authorization: 'Bearer ' + user.jwt,
          },
        }
      );
      setUsageLimit(data.usageLimit ? data.usageLimit : 0);
    } catch (err) {
      dispatch(logout());
    }
  };

  useEffect(() => {
    if (user === null || user === {}) {
      history.push('/login');
    } else {
      getUsageLimit();
    }
  }, [user]);

  const monthDiff = (d1, d2) => {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  };

  const lastDay = (y, m) => {
    return new Date(y, m + 1, 0).getDate();
  };

  const weekCount = (y, m) => {
    var firstOfMonth = new Date(y, m - 1, 1);
    var lastOfMonth = new Date(y, m, 0);

    var used = firstOfMonth.getDay() + lastOfMonth.getDate();

    return Math.ceil(used / 7);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!fileUploaded) {
      alert('Please upload 5-month chapter PALMS report!');
      return;
    }

    await readFileAndGetWorksheet(
      event.target[0].files[0],
      async (worksheet) => {
        const memberData = [];
        const selectedMembers = members.filter((m) => m.selected);

        selectedMembers.forEach((sm) => {
          const splitCell = sm.cell.split('-');
          const letter = splitCell[0];
          const number = splitCell[1];

          const nameCell = worksheet['A' + number];
          let name = nameCell ? nameCell.v : undefined;

          const nameCell2 = worksheet['B' + number];
          let name2 = nameCell2 ? nameCell2.v : undefined;

          name = name + ' ' + name2;

          let startDate;
          let endDate;
          if (worksheet['A8'].v.includes('First')) {
            startDate = moment(worksheet['I6'].w, 'DD/MM/YYYY').toDate();
            endDate = moment(worksheet['I7'].w, 'DD/MM/YYYY').toDate();
          } else {
            startDate = new Date(worksheet['I6'].w);
            endDate = new Date(worksheet['I7'].w);
          }

          const pCell = worksheet['C' + number];
          const p = pCell ? pCell.v : undefined;

          const aCell = worksheet['D' + number];
          const a = aCell ? aCell.v : undefined;

          const mCell = worksheet['F' + number];
          const m = mCell ? mCell.v : undefined;

          const sCell = worksheet['G' + number];
          const s = sCell ? sCell.v : undefined;

          const w = parseInt(p) + parseInt(a) + parseInt(m) + parseInt(s);

          const lCell = worksheet['E' + number];
          const l = lCell ? lCell.v : undefined;

          const gCell = worksheet['H' + number];
          let g = gCell ? gCell.v : undefined;

          const gCell2 = worksheet['J' + number];
          const g2 = gCell2 ? gCell2.v : undefined;

          g = g + g2;

          const rCell = worksheet['K' + number];
          let r = rCell ? rCell.v : undefined;

          const rCell2 = worksheet['L' + number];
          const r2 = rCell2 ? rCell2.v : undefined;

          r = r + r2;

          const vCell = worksheet['M' + number];
          const v = vCell ? vCell.v : undefined;

          const dCell = worksheet['N' + number];
          const d = dCell ? dCell.v : undefined;

          const bgCell = worksheet['O' + number];
          const bg = bgCell ? bgCell.v : undefined;

          const tCell = worksheet['P' + number];
          const t = tCell ? tCell.v : undefined;

          const nextMonth = new Date(endDate);
          nextMonth.setDate(nextMonth.getDate() + 1);

          const nextNoOfWks = weekCount(
            nextMonth.getFullYear(),
            nextMonth.getMonth()
          );

          console.log(w + nextNoOfWks);
          console.log(w);
          console.log(nextNoOfWks);

          memberData.push({
            currentAbs: a,
            currentBizGiven: bg,
            currentDance: d,
            currentLate: l,
            currentReferral: g,
            currentTraining: t,
            currentVisitor: v,
            week: w,
            futureWeek: w + nextNoOfWks,
            name,
            cell: sm.cell,
          });
        });

        try {
          const res = await axios.post(
            process.env.REACT_APP_API_URL + '/bniLogics/getSuggestionSetBatch',
            {
              memberData,
            },
            {
              headers: {
                Authorization: 'Bearer ' + user.jwt,
              },
            }
          );

          dispatch(
            setData({
              results: res.data,
            })
          );

          history.push('/suggestionResult');
        } catch (err) {
          if (
            err.response.data.statusCode === 400 &&
            err.response.data.message === 'Exceed usage limit!'
          ) {
            alert('Exceed usage limit! Please purchase usage.');
          } else {
            alert('Cannot authenticate user. Please login again.');
            dispatch(logout());
          }
        }
      }
    );
  };

  const handleChange = (event, name) => {
    setForm({ ...form, [name]: event.target.value });
  };

  const handleFileChange = (e) => {
    setTrafficLightExcelReportInput(e.target.files[0].name);
    setFileUploaded(true);
    if (!fillMembers(e.target.files[0])) {
      e.preventDefault();
      return;
    }
  };

  const fillMembers = (file) => {
    let error = false;
    readFileAndGetWorksheet(file, (worksheet) => {
      let limiterFirstNameCell = 1;
      const startOfNameColLetter = 'A';
      let testCellValue = '';
      let addressOfFirstNameCell = 'A9'; // A6

      let startDate;
      let endDate;
      if (worksheet['A8'].v.includes('First')) {
        startDate = moment(worksheet['I6'].w, 'DD/MM/YYYY').toDate();
        endDate = moment(worksheet['I7'].w, 'DD/MM/YYYY').toDate();
      } else {
        startDate = new Date(worksheet['I6'].w);
        endDate = new Date(worksheet['I7'].w);
      }

      // if (
      //   monthDiff(startDate, endDate) != 4 ||
      //   startDate.getDate() != 1 ||
      //   endDate.getDate() != lastDay(endDate.getFullYear(), endDate.getMonth())
      // ) {
      //   alert(
      //     'Require to upload 5-month chapter PALMS report! Also, report start date must be the first day of a month and end date must be the last day of a month.'
      //   );
      //   return;
      // }

      while (testCellValue !== '姓氏' || !testCellValue.includes('First')) {
        addressOfFirstNameCell = startOfNameColLetter + limiterFirstNameCell;
        const nameCell = worksheet[addressOfFirstNameCell];
        const nameValue = nameCell ? nameCell.v : undefined;
        testCellValue = nameValue;
        if (testCellValue === '姓氏' || testCellValue.includes('First')) {
          break;
        }

        limiterFirstNameCell = limiterFirstNameCell + 1;
        if (limiterFirstNameCell > 200) {
          alert('The report format is wrong!');
          error = true;
          break;
        }
      }
      limiterFirstNameCell = limiterFirstNameCell + 1;
      addressOfFirstNameCell = startOfNameColLetter + limiterFirstNameCell;

      let limiterLastNameCell = 1;
      testCellValue = ' ';
      let testCellValue2 = ' ';
      let addressOfLastNameCell = '';
      let addressOfNextToLastNameCell;

      if (error === true) {
        return false;
      }

      while (
        !(
          (testCellValue2 === '來賓' || testCellValue2.includes('Visitors')) &&
          limiterLastNameCell > limiterFirstNameCell
        )
      ) {
        addressOfLastNameCell = startOfNameColLetter + limiterLastNameCell;
        addressOfNextToLastNameCell =
          startOfNameColLetter + (limiterLastNameCell + 1);
        const nameCell = worksheet[addressOfLastNameCell];
        const nameValue = nameCell ? nameCell.v : undefined;
        const nameCell2 = worksheet[addressOfNextToLastNameCell];
        const nameValue2 = nameCell2 ? nameCell2.v : undefined;

        testCellValue = nameValue;
        testCellValue2 = nameValue2;

        if (
          (testCellValue2 === '來賓' || testCellValue2.includes('Visitors')) &&
          limiterLastNameCell > limiterFirstNameCell
        ) {
          break;
        }

        limiterLastNameCell = limiterLastNameCell + 1;
        if (limiterLastNameCell > 200) {
          alert('The report format is wrong!');
          error = true;
          break;
        }
      }

      error = true;

      const membersTemp = [];
      for (let i = limiterFirstNameCell; i < limiterLastNameCell + 1; i++) {
        const nameCell1 = worksheet['A' + i];
        const nameValue1 = nameCell1 ? nameCell1.v : undefined;
        const nameCell2 = worksheet['B' + i];
        const nameValue2 = nameCell2 ? nameCell2.v : undefined;

        const member = {
          name: nameValue1 + ' ' + nameValue2,
          cell: 'B-' + i,
          index: i,
          selected: false,
        };
        membersTemp.push(member);

        if (i === limiterFirstNameCell) {
          form.selectedMember = member.cell;
        }
      }

      membersTemp.sort((a, b) => (a.name > b.name ? 1 : -1));

      setMembers(membersTemp);
      return true;
    });
  };

  const readFileAndGetWorksheet = (file, callBack) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      callBack(worksheet);
    };

    reader.readAsArrayBuffer(file);
  };

  const onRowSelect = (row, isSelected) => {
    row.selected = isSelected;
    const membersTemp = [...members];
    setMembers(membersTemp);
  };

  const onSelectAll = (isSelected, currentDisplayAndSelectedData) => {
    for (let i = 0; i < currentDisplayAndSelectedData.length; i++) {
      currentDisplayAndSelectedData[i].selected = isSelected;
    }
    const membersTemp = [...members];
    setMembers(membersTemp);
  };

  const selectRowProp = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: onRowSelect,
    onSelectAll: onSelectAll,
  };

  useEffect(() => {}, []);

  return (
    <div>
      <header className='masthead content'>
        <div className='layer'></div>

        <div className='container h-100'>
          <div className='row h-100 align-items-center'>
            <div className='col-12 text-center'>
              <h1 className='font-weight-light' style={{ color: '#fff' }}>
                Green Light Suggestion Planner
              </h1>
            </div>
          </div>
        </div>
      </header>

      <section className='py-5'>
        <div className='container'>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.File
                label={trafficLightExcelReportInput}
                accept='.xlsx, .xls'
                custom
                onChange={handleFileChange}
              />
            </Form.Group>

            <Form.Group>
              <BootstrapTable
                data={members}
                selectRow={selectRowProp}
                condensed
                striped
                hover
              >
                <TableHeaderColumn isKey dataField='name' dataSort={true}>
                  Member Name
                </TableHeaderColumn>
              </BootstrapTable>
            </Form.Group>

            <p>
              <input
                className='btn btn-lg'
                style={submitButton}
                type='submit'
                value='Get Suggestion'
              ></input>
            </p>
          </Form>
          <p>Current Usage Limit: {usageLimit}</p>
        </div>
      </section>

      <section className='py-2'>
        <div className='container'>
          <h2 className='font-weight-light'>Remark</h2>
          <p>Via this system, you will get green light suggestion.</p>
          <p>
            This is a system developed by{' '}
            <a href='https://www.originbit.asia' target='_blank'>
              <strong>OriginBit</strong>
            </a>{' '}
            - a leading IT powerhouse, we specialize in crafting game-changing
            ERP System Solutions, expert Business & IT consultation, innovative
            Web & Mobile App Development, and revolutionary AI, Cloud and
            Blockchain Solutions.. To know more about us, please check our{' '}
            <a
              href='https://www.originbit.asia'
              target='_blank'
              style={{ fontWeight: '500' }}
            >
              <strong>website here</strong>
            </a>
            . Enjoy!
          </p>
          <p>
            <br></br>
            <small>
              <em>
                Powered by <img alt='' src={obLogo} width='60' />
                <a href='https://www.originbit.asia' target='_blank'>
                  OriginBit
                </a>
                , <img alt='' src={bniQuantumLogo} width='60' /> BNI Quantum,
                and <img alt='' src={bniRisingLogo} width='60' /> BNI Rising.
              </em>
            </small>
          </p>
        </div>
      </section>
    </div>
  );
};

const TipsBadgeStyle = {
  fontSize: '1.5rem',
  backgroundColor: '#ce2030',
  padding: '7px',
};

const formLabel = {
  fontSize: '1rem',
  fontWeight: '600',
};

const submitButton = {
  backgroundColor: '#ce2030',
  padding: '10px',
  color: '#fff',
  borderRadius: '15px',
};

export default SuggestionScreen;
