import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Header from './components/Header';
import SuggestionScreen from './screens/SuggestionScreen';
import SuggestionResultScreen from './screens/SuggestionResultScreen';
import { LoginScreen } from './screens/LoginScreen';
import { RegisterScreen } from './screens/RegisterScreen';
import { PaymentScreen } from './screens/PaymentScreen';
import { ProfileScreen } from './screens/ProfileScreen';
import { HomeScreen } from './screens/HomeScreen';

function App() {
  return (
    <Router>
      <Header />
      <main>
        <Route path='/' exact component={HomeScreen} />
        <Route path='/suggestion' component={SuggestionScreen} />
        <Route path='/suggestionResult' component={SuggestionResultScreen} />
        <Route path='/login' component={LoginScreen} />
        <Route path='/register' component={RegisterScreen} />
        <Route path='/payment' component={PaymentScreen} />
        <Route path='/profile' component={ProfileScreen} />
      </main>
    </Router>
  );
}

export default App;
