import { USER_SET_DATA, USER_LOGOUT } from '../constants/userConstants';

export const setData = (data) => (dispatch) => {
  dispatch({
    type: USER_SET_DATA,
    payload: data,
  });

  localStorage.setItem('userInfo', JSON.stringify(data));
};

export const logout = () => (dispatch) => {
  localStorage.removeItem('userInfo');
  dispatch({ type: USER_LOGOUT });
  document.location.href = '/login';
};
