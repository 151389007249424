import React, { useState, useEffect } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { NavDropdown, Nav, Navbar, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../actions/userActions';
import obLogo from '../images/OriginBit_white.png';

const Header = () => {
  const userInfo = useSelector((state) => state.user);
  const { user } = userInfo;
  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <header>
      <Navbar bg='dark' variant='dark' expand='lg' collapseOnSelect>
        <Container>
          <LinkContainer to='/'>
            <Navbar.Brand>
              <img alt='' src={obLogo} width='60' /> OriginBit System
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />

          <Navbar.Collapse id='basic-navbar-nav'>
            {user ? (
              <Nav className='ml-auto mr-2'>
                <LinkContainer to='/suggestion'>
                  <Nav.Link>Suggestion Planner</Nav.Link>
                </LinkContainer>
                <NavDropdown title={user.user.username} id='username'>
                  <LinkContainer to='/profile'>
                    <NavDropdown.Item>
                      <i className='fas fa-user'></i> Profile
                    </NavDropdown.Item>
                  </LinkContainer>

                  <LinkContainer to='/payment'>
                    <NavDropdown.Item>
                      <i className='fas fa-shopping-cart'></i> Purchase Usage
                    </NavDropdown.Item>
                  </LinkContainer>

                  <NavDropdown.Item onClick={logoutHandler}>
                    <i className='fas fa-sign-out-alt'></i> Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            ) : (
              <Nav className='ml-auto mr-2'>
                <LinkContainer to='/register'>
                  <Nav.Link>
                    <i className='fas fa-file-alt'></i> Register
                  </Nav.Link>
                </LinkContainer>

                <LinkContainer to='/login'>
                  <Nav.Link>
                    <i className='fas fa-user'></i> Login
                  </Nav.Link>
                </LinkContainer>
              </Nav>
            )}
            {/* <Navbar.Text>
              <small>
                Powered by{' '}
                <a
                  href='https://www.originbit.asia'
                  style={{ color: 'white' }}
                  target='_blank'
                >
                  OriginBit
                </a>
              </small>
            </Navbar.Text> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
