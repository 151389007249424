import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
require('dotenv').config();

export const PaymentForm = ({ user, paid }) => {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const [suggestion, setSuggestion] = useState(100);

  const createPaymentIntent = async (value) => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_API_URL +
          '/bniLogics/createPaymentIndent/' +
          value,
        {
          headers: {
            Authorization: 'Bearer ' + user.jwt,
          },
        }
      );

      setClientSecret(data.clientSecret);
      return data.clientSecret;
    } catch (err) {}
  };

  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const cardStyle = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#32325d',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
    const clientSecretTemp = await createPaymentIntent(suggestion);
    const payload = await stripe.confirmCardPayment(clientSecretTemp, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });
    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);

      try {
        await axios.post(
          process.env.REACT_APP_API_URL + '/bniLogics/createPaymentRecord',
          {
            payload,
          },
          {
            headers: {
              Authorization: 'Bearer ' + user.jwt,
            },
          }
        );

        alert('Success!');
      } catch (err) {
        alert('Payment error: ' + err.response.data.message);
      }

      paid();
    }
  };

  return (
    <Form className='payment-form' onSubmit={handleSubmit}>
      <Form.Group>
        Number of Suggestions 購買可用次數
        <Form.Control
          as='select'
          onChange={async (e) => {
            setDisabled(true);
            setSuggestion(e.target.value);
            setDisabled(false);
          }}
        >
          <option value='100'>100</option>
          <option value='200'>200</option>
          <option value='300'>300</option>
          <option value='400'>400</option>
          <option value='500'>500</option>
          <option value='600'>600</option>
          <option value='700'>700</option>
          <option value='800'>800</option>
          <option value='900'>900</option>
          <option value='1000'>1000</option>
        </Form.Control>
      </Form.Group>

      <CardElement
        id='card-element'
        options={cardStyle}
        onChange={handleChange}
        className='payment-input'
      />
      <button
        disabled={processing || disabled || succeeded}
        id='submit'
        className='payment-button'
      >
        <span id='button-text'>
          {processing ? (
            <div className='spinner' id='spinner'></div>
          ) : (
            'Pay now'
          )}
        </span>
      </button>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className='card-error' role='alert'>
          {error}
        </div>
      )}
      {/* Show a success message upon completion */}
      <p
        className={
          succeeded ? 'result-message' : 'result-message payment-hidden'
        }
      >
        Payment succeeded, see the result in your
        <a href={`https://dashboard.stripe.com/test/payments`}>
          {' '}
          Stripe dashboard.
        </a>{' '}
        Refresh the page to pay again.
      </p>
      <br></br>
      <h5>Total 總數: HK${suggestion * 0.5} </h5>
    </Form>
  );
};
