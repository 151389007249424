import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { PaymentForm } from '../components/PaymentForm';
import axios from 'axios';
import { logout } from '../actions/userActions';
require('dotenv').config();

const promise = loadStripe('pk_live_tUiTD1Zltura3IQPhAeGwTGe');

export const PaymentScreen = ({ history }) => {
  const userInfo = useSelector((state) => state.user);
  const { user } = userInfo;
  const dispatch = useDispatch();

  const [usageLimit, setUsageLimit] = useState(0);

  const redirect = '/login';

  const getUsageLimit = async () => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_API_URL + '/bniLogics/getUserUsageLimit/',
        {
          headers: {
            Authorization: 'Bearer ' + user.jwt,
          },
        }
      );
      setUsageLimit(data.usageLimit);
    } catch (err) {
      dispatch(logout());
    }
  };

  useEffect(async () => {
    if (!user || user === null || user === undefined) {
      history.push(redirect);
    }

    getUsageLimit();
  }, [history, user, redirect]);

  const paid = () => {
    history.push('/profile');
  };

  return (
    <div>
      <header className='masthead content'>
        <div className='layer'></div>

        <div className='container h-100'>
          <div className='row h-100 align-items-center'>
            <div className='col-12 text-center'>
              <h1 className='font-weight-light' style={{ color: '#fff' }}>
                Purchase Usage
              </h1>
              <p className='lead' style={{ color: '#fff' }}>
                HK$50 to get 100 suggestions!
              </p>
            </div>
          </div>
        </div>
      </header>

      <section className='py-5'>
        <div className='container'>
          <Elements stripe={promise}>
            <PaymentForm user={user} paid={paid}></PaymentForm>
          </Elements>
          <br></br>
          <p>Current Usage Limit: {usageLimit ? usageLimit : 0}</p>
        </div>
      </section>
    </div>
  );
};
