import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { setData } from '../actions/userActions';
require('dotenv').config();

export const RegisterScreen = ({ history }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [chapter, setChapter] = useState('');

  const userInfo = useSelector((state) => state.user);
  const { user } = userInfo;

  const redirect = '/suggestion';

  useEffect(() => {
    if (user) {
      history.push(redirect);
    }
  }, [history, user, redirect]);

  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      username.trim().length <= 0 ||
      password.trim().length <= 0 ||
      username.trim().length <= 0 ||
      email.trim().length <= 0
    ) {
      alert('Empty username or password');
      return;
    }

    try {
      const res = await axios.post(
        process.env.REACT_APP_API_URL + '/auth/local/register',
        {
          username: username,
          email: email,
          password: password,
          chapter: chapter,
        }
      );

      dispatch(setData(res.data));
      history.push(redirect);
    } catch (err) {
      alert('Duplicated username or email.');
    }
  };

  return (
    <div>
      <header className='masthead content'>
        <div className='layer'></div>
        <div className='container h-100'>
          <div className='row h-100 align-items-center'>
            <div className='col-12 text-center'>
              <h1 className='font-weight-light' style={{ color: '#fff' }}>
                Registration
              </h1>
            </div>
          </div>
        </div>
      </header>
      <section className='py-5'>
        <div className='container'>
          <Form onSubmit={handleSubmit}>
            <div className='form-group'>
              <label htmlFor='inputUsername'>Username</label>
              <Form.Control
                type='text'
                className='form-control'
                id='inputUsername'
                placeholder='Enter username'
                onChange={username}
                onChange={(e) => setUsername(e.target.value)}
              ></Form.Control>
            </div>
            <div className='form-group'>
              <label htmlFor='inputEmail'>Email</label>
              <Form.Control
                type='email'
                className='form-control'
                id='inputEmail'
                placeholder='Enter email'
                onChange={email}
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </div>
            <div className='form-group'>
              <label htmlFor='inputChapter'>BNI Chapter</label>
              <Form.Control
                type='text'
                className='form-control'
                id='inputChapter'
                placeholder='Enter chapter'
                onChange={chapter}
                onChange={(e) => setChapter(e.target.value)}
              ></Form.Control>
            </div>
            <div className='form-group'>
              <label htmlFor='inputPassword'>Password</label>
              <Form.Control
                type='password'
                className='form-control'
                id='inputPassword'
                placeholder='Enter password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              ></Form.Control>
            </div>
            <button type='submit' className='btn btn-primary'>
              Register
            </button>
          </Form>
          <br></br>
          <p>
            <em>
              Note: You will need OriginBit's approval in order to use the
              system, please contact us at{' '}
              <a href='tel:92025576'>+852 9202 5576</a> or{' '}
              <a href='mailto:contact@originbit.asia'>contact@originbit.asia</a>
            </em>
          </p>
        </div>
      </section>
    </div>
  );
};
