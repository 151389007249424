import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { setData } from '../actions/userActions';
require('dotenv').config();

export const LoginScreen = ({ history }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const userInfo = useSelector((state) => state.user);
  const { user } = userInfo;

  const redirect = '/suggestion';

  useEffect(() => {
    if (user) {
      history.push(redirect);
    }
  }, [history, user, redirect]);

  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (username.trim().length <= 0 || password.trim().length <= 0) {
      alert('Empty username or password');
      return;
    }

    try {
      const res = await axios.post(
        process.env.REACT_APP_API_URL + '/auth/local',
        {
          identifier: username,
          password: password,
        }
      );

      dispatch(setData(res.data));
    } catch (err) {
      alert('Wrong username or password');
    }
  };

  return (
    <div>
      <header className='masthead content'>
        <div className='layer'></div>
        <div className='container h-100'>
          <div className='row h-100 align-items-center'>
            <div className='col-12 text-center'>
              <h1 className='font-weight-light' style={{ color: '#fff' }}>
                Login
              </h1>
            </div>
          </div>
        </div>
      </header>
      <section className='py-5'>
        <div className='container'>
          <Form onSubmit={handleSubmit}>
            <div className='form-group'>
              <label htmlFor='inputUsername'>Username</label>
              <Form.Control
                type='text'
                className='form-control'
                id='inputUsername'
                placeholder='Enter username'
                onChange={username}
                onChange={(e) => setUsername(e.target.value)}
              ></Form.Control>
            </div>
            <div className='form-group'>
              <label htmlFor='inputPassword'>Password</label>
              <Form.Control
                type='password'
                className='form-control'
                id='inputPassword'
                placeholder='Enter password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              ></Form.Control>
            </div>
            <button type='submit' className='btn btn-primary'>
              Login
            </button>
          </Form>
        </div>
      </section>
    </div>
  );
};
