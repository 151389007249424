import React from 'react';
import suggestionImg from '../images/suggestion.png';
import vid from '../images/video.mp4';
import poster from '../images/poster.png';
import { ReactVideo } from 'reactjs-media';
import obLogo from '../images/OriginBit_black.png';
import bniQuantumLogo from '../images/bni_q_logo.png';
import bniRisingLogo from '../images/rising_logo.png';

export const HomeScreen = () => {
  return (
    <div>
      <header className='masthead'>
        <div className='layer'></div>

        <div className='container h-100'>
          <div className='row h-100 align-items-center'>
            <div className='col-12 text-center'>
              <a
                href='https://www.originbit.asia'
                style={{ color: 'white' }}
                target='_blank'
              >
                <img
                  alt=''
                  src={obLogo}
                  style={{ maxWidth: '200px', marginBottom: '10px' }}
                />
              </a>
              <p className='lead' style={{ color: '#fff' }}>
                One-click Green Light Suggestion Planner
              </p>
            </div>
          </div>
        </div>
      </header>

      <section className='py-5'>
        <div className='container'>
          <h2 className='font-weight-light'>Introduction</h2>
          <p>Via this system, you will get green light suggestion.</p>
          <p>
            This is a system developed by{' '}
            <a href='https://www.originbit.asia' target='_blank'>
              <strong>OriginBit</strong>
            </a>{' '}
            - a leading IT powerhouse, we specialize in crafting game-changing
            ERP System Solutions, expert Business & IT consultation, innovative
            Web & Mobile App Development, and revolutionary AI, Cloud and
            Blockchain Solutions.. To know more about us, please check our{' '}
            <a
              href='https://www.originbit.asia'
              target='_blank'
              style={{ fontWeight: '500' }}
            >
              <strong>website here</strong>
            </a>
            . Enjoy!
          </p>
          <p>
            <br></br>
            <small>
              <em>
                Powered by{' '}
                <a href='https://www.originbit.asia' target='_blank'>
                  <img alt='' src={obLogo} width='60' />
                </a>
              </em>
            </small>
          </p>
          <p>
            <img
              className='shadow img-fluid'
              src={suggestionImg}
              alt='suggestion plan sample'
              style={{ maxWidth: '100%', width: '400px' }}
            ></img>
          </p>
        </div>
      </section>

      <section>
        <div className='container'>
          <h2 className='font-weight-light'>User Guide (Video)</h2>
          <p>We have prepared a video to teach you how to use the system.</p>
          <div className='mb-4'>
            <ReactVideo poster={poster} src={vid} />
          </div>
        </div>
      </section>

      <section>
        <div className='container'>
          <p>
            <br></br>
            <small>
              <em>
                Powered by{' '}
                <a href='https://www.originbit.asia' target='_blank'>
                  <img alt='' src={obLogo} width='60' />
                </a>
              </em>
            </small>
          </p>
        </div>
      </section>
    </div>
  );
};
