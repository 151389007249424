import { USER_SET_DATA, USER_LOGOUT } from '../constants/userConstants';

export const userReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_SET_DATA:
      return {
        ...state,
        user: action.payload,
      };

    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};
